import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './PageCard.module.scss';
import Icon from '../Icon';
import Image from '../Image';

const PageCard = ({
    title,
    preamble,
    link,
    image,
    publishedDate,
    showLinkText = true,
}) => {
    return (
        <a href={link.href} className={styles['PageCard']}>
            <div className={styles['PageCard__ImageWrap']}>
                {image.url && (
                    <Image
                        {...image}
                        fill={true}
                        objectFit="cover"
                        onLoadingComplete={() => setHasLoadedImage(true)}
                    />
                )}
            </div>

            <div className={styles['PageCard__Content']}>
                <div className={styles['NewsCard__Top']}>
                    <h3 className={styles['PageCard__Title']}>{title}</h3>
                    <p className={styles['PageCard__Preamble']}>{preamble}</p>
                </div>
                <div className={styles['PageCard__Bottom']}>
                    <span className={styles['PageCard__Date']}>
                        {publishedDate}
                    </span>
                    <div className={styles['PageCard__Link']}>
                        <span className={styles['PageCard__LinkText']}>
                            {showLinkText ? 'Read more' : ''}
                        </span>
                        <Icon type="arrow" size="large" />
                    </div>
                </div>
            </div>
        </a>
    );
};

PageCard.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    publishedDate: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
};

PageCard.defaultProps = {
    image: {},
    link: {},
};

export default PageCard;
