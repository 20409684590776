import NewsCard from '../NewsCard';
import Button from '../Button';
import classNames from 'classnames';
import styles from './NewsCardList.module.scss';
import PageCard from '../PageCard';

const NewsCardList = ({ title, link, items, isRow }) => {
    const classes = classNames(styles['NewsCardList'], {
        [styles['NewsCardList--Row']]: isRow,
    });

    return (
        <div className={classes}>
            <div className={styles['NewsCardList__Container']}>
                <div className={styles['NewsCardList__TitleWrapper']}>
                    <h2 className={styles['NewsCardList__Title']}>{title}</h2>
                    {link && (
                        <Button link={link} type={'tertiary'} icon={'arrow'} />
                    )}
                </div>

                {items && (
                    <div className={styles['NewsCardList__List']}>
                        {items.map((item, i) => (
                            <div
                                key={i}
                                className={classNames(
                                    styles['NewsCardList__Item'],
                                    styles[`NewsCardList__Item--${i + 1}`],
                                    {
                                        [styles['NewsCardList__Item--Row']]:
                                            isRow,
                                    }
                                )}>
                                {isRow ? (
                                    <PageCard {...item} showLinkText={false} />
                                ) : (
                                    <NewsCard {...item} index={i + 1} />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

NewsCardList.propTypes = {};

NewsCardList.defaultProps = {};

export default NewsCardList;
